
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatableLeft.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import EntityModal from "@/components/modals/forms/entity/EntityModal.vue";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";

export default defineComponent({
  name: "pending-map-assessor-with-institute",
  components: {
    EntityModal,
    Datatable,
  },
  data() {
    return {
      componentkey: 0,
      batch: {},
      employee: {},
      actionApprove: false,
      actionEdit: false,
      actionReject: false,
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },

        {
          name: "Assessore",
          key: "assessore",
          sortable: true,
        },
        {
          name: "Institute",
          key: "institute",
          sortable: true,
        },
      ],
      tableData: [
        // {
        //   id: 1,
        //   assessore: {
        //     name: "Zohirul Alam Tiemoon",
        //     email: "tiemoon@gmail.com",
        //     phone: "+8801756888319",
        //     gender: "Male",
        //   },
        //   institute: {
        //     name: "A.B.C Bangladesh-Turkish Technical School",
        //     email: "abcbdtktschool@yahoo.com",
        //     phone: "0312552697",
        //     website: "www.doulatpurabchighschool.edu.bd",
        //     address: "Nazirhat Municipality ,Fatickchari",
        //   },
        // },
        // {
        //   id: 2,
        //   assessore: {
        //     name: "Md. Nahidur Rahman Rifath",
        //     email: "nrifath2009@gmail.com",
        //     phone: "+8801672089753",
        //     gender: "Male",
        //   },
        //   institute: {
        //     name: "ACA Technical Institute",
        //     email: "aca.seip@gmail.com",
        //     phone: "+8801875889764",
        //     website: "www.aca.org",
        //     address: "Faterpur, Enayetnagar, Matlab Uttor, Chandpur",
        //   },
        // },
      ] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.MapAssesorInstituteList();
    this.emitter.on("pendingAssesorInstitute", async () => {
      await this.MapAssesorInstituteList();
    });
    await this.actioncheck();
  },
  methods: {
    async MapAssesorInstituteList() {
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      let mapping_type = "1";
      let active_status = 0;
      await ApiService.get(
        "entity/assessor/map_assessor_info?mapping_type=" +
          mapping_type +
          "&active_status=" +
          active_status +
          "&entity_id=" +
          entity_id
      )
        .then((response) => {
          this.tableData = response.data.data;

          this.loading = false;
          this.componentkey += 1;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem("menu") || "{}");
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == "Assessor") {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === "Approve") {
                this.actionApprove = true;
              }
              if (actions[j].action_name === "Edit") {
                this.actionEdit = true;
              }
              if (actions[j].action_name === "Reject") {
                this.actionReject = true;
              }
            }
          }
        }
      }
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    edit(data) {
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.emitter.emit("add-modal-data", true);
    },
    Approve(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: "Are you sure you want to Approve it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approve!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(
            "entity/assessor/map-status-change/" + `${id}`,
            data
          )
            .then((response) => {
              this.emitter.emit("pendingAssesorInstitute", true);
              this.componentkey += 1;
              Swal.fire("Approved!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Suspend(id) {
      let data = {
        active_status: 2,
      };
      Swal.fire({
        title: "Are you sure you want to suspend it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Suspend!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(
            "entity/assessor/map-status-change/" + `${id}`,
            data
          )
            .then((response) => {
              this.emitter.emit("pendingAssesorInstitute", true);
              Swal.fire("Suspended!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
